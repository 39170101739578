// import React, { useState, useEffect, useRef } from "react";
// import { questions } from "./constants";
// import { Chip, Divider } from "@nextui-org/react";

// function DisplayAnswers({ answers, username, userImages }) {
//   const [imageLinks, setImageLinks] = useState([]);  // Changed to array
//   const [showImage, setShowImage] = useState(false);
//   const [isReadyForConnection, setIsReadyForConnection] = useState(false);
//   const wsRef = useRef(null);

//   const generatePrompt = () => {
//     let moodDescription;
//     switch (answers[0]) {
//       case "happy":
//         moodDescription = "is laughing with joy";
//         break;
//       case "excited":
//         moodDescription = "can't contain their excitement";
//         break;
//       case "sad":
//         moodDescription = "looks around with a sad face";
//         break;
//       case "angry":
//         moodDescription = "looks around with an angry face";
//         break;
//       case "adventurous":
//         moodDescription = "is ready to leap into adventure";
//         break;
//       default:
//         moodDescription = "is full of wonder";
//     }

//     const prompt = `a child ${moodDescription}, wearing a ${
//       answers[1]
//     } shirt, with ${answers[3]}, ${"exploring" + " " + answers[2]}.`;

//     return prompt;
//   };

//   useEffect(() => {
//     setIsReadyForConnection(true);
//   }, []);

//   useEffect(() => {
//     const webSocketUrl = `ws://localhost:8000/user/generate?email_id=${encodeURIComponent(
//       username
//     )}&prompt=${encodeURIComponent(generatePrompt())}`;

//     const ws = new WebSocket(webSocketUrl);
//     wsRef.current = ws;

//     ws.onmessage = (event) => {
//       const data = JSON.parse(event.data);
//       if (data.image_link) {
//         const links = JSON.parse(data.image_link);
//         setImageLinks(links);
//         setShowImage(true);
//       }
//     };

//     ws.onclose = () => {
//       console.log("WebSocket closed");
//       wsRef.current = null;
//     };

//     ws.onerror = (error) => {
//       console.error("WebSocket error:", error);
//     };

//     return () => {
//       if (ws.readyState === WebSocket.OPEN) {
//         ws.close();
//       }
//     };
//   }, [username]);

//   return (
//     <div className="answers-container">
//       <Chip color="success" size="md">
//         Your Answers:
//       </Chip>
//       <div className="space"></div>

//       {answers.map((answer, idx) => (
//         <div key={idx} className="answer">
//           {questions[idx].question}: <strong>{answer}</strong>
//         </div>
//       ))}
//       <br />
//       {showImage && imageLinks.length > 0 && (
//         <div>
//           <h3>Generated Images:</h3>
//           {imageLinks.map((link, idx) => (
//             <img key={idx} src={link} alt={`generated image ${idx + 1}`} />
//           ))}
//         </div>
//       )}
//       <Divider className="space" orientation="horizontal" />
//       {!showImage && (
//         <div>
//           <span>Your request has been submitted.</span>
//           <br />
//           <span>Stay on this page to see images appear.</span>
//         </div>
//       )}
//     </div>
//   );
// }

// export default DisplayAnswers;



import React, { useState, useEffect, useRef } from "react";
import { questions } from "./constants";
import { Chip, Divider } from "@nextui-org/react";

function DisplayAnswers({ answers, username }) {
  const [imageLinks, setImageLinks] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const wsRef = useRef(null);

  const generatePrompt = () => {
    let moodDescription;
    switch (answers[0]) {
      case "happy":
        moodDescription = "is laughing with joy";
        break;
      case "excited":
        moodDescription = "can't contain their excitement";
        break;
      case "sad":
        moodDescription = "looks around with a sad face";
        break;
      case "angry":
        moodDescription = "looks around with an angry face";
        break;
      case "adventurous":
        moodDescription = "is ready to leap into adventure";
        break;
      default:
        moodDescription = "is full of wonder";
    }

    const prompt = `a child ${moodDescription}, wearing a ${
      answers[1]
    } shirt, with ${answers[3]}, ${"exploring" + " " + answers[2]}.`;

    return prompt;
  };

  useEffect(() => {
    const webSocketUrl = `wss://fast-api.ilore.ai/user/generate?email_id=${encodeURIComponent(
      username
    )}&prompt=${encodeURIComponent(generatePrompt())}`;

    const ws = new WebSocket(webSocketUrl);
    wsRef.current = ws;

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.image_links) {
        setImageLinks(data.image_links);
        setShowImage(true);
      }
    };

    ws.onclose = () => {
      console.log("WebSocket closed");
      wsRef.current = null;
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  }, [username]);

  return (
    <div className="answers-container">
      <Chip color="success" size="md">
        Your Answers:
      </Chip>
      <div className="space"></div>

      {answers.map((answer, idx) => (
        <div key={idx} className="answer">
          {questions[idx].question}: <strong>{answer}</strong>
        </div>
      ))}
      <br />
      {showImage && imageLinks.length > 0 && (
        <div>
          <h3>Generated Images:</h3>
          {imageLinks.map((link, idx) => (
            <img key={idx} src={link} alt={`generated image ${idx + 1}`} />
          ))}
        </div>
      )}
      <Divider className="space" orientation="horizontal" />
      {!showImage && (
        <div>
          <span>Your request has been submitted.</span>
          <br />
          <span>Stay on this page to see images appear.</span>
        </div>
      )}
    </div>
  );
}

export default DisplayAnswers;
