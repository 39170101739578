import React, { useEffect, useState } from "react";
import axios from "axios";
import { Chip, Divider } from "@nextui-org/react";

const DataStreamComponent = () => {
  const [users, setUsers] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [models, setModels] = useState([]);
  const [generations, setGenerations] = useState([]);
  const [error, setError] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState(false);
  const [ws, setWs] = useState(null);

  useEffect(() => {
    const connectWebSocket = () => {
      const newWs = new WebSocket("wss://fast-api.ilore.ai/logs/data-stream");

      newWs.onopen = () => {
        console.log("WebSocket Connected");
        setConnectionStatus(true);
      };

      newWs.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          setUsers(data.users);
          setDatasets(data.datasets);
          setModels(data.models);
          setGenerations(data.generations);
        } catch (e) {
          setError("Error parsing data");
          console.error(e);
        }
      };

      newWs.onerror = (error) => {
        console.error("WebSocket error:", error);
        setConnectionStatus(false);
        newWs.close();
      };

      newWs.onclose = () => {
        console.log("WebSocket Disconnected");
        setConnectionStatus(false);
        setTimeout(connectWebSocket, 3000);
      };

      setWs(newWs);
    };

    if (!ws) {
      connectWebSocket();
    }

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [ws]);

  const renderBoolean = (value) => (value === 1 ? "✔️" : "❌");

  const renderTableCell = (value) => {
    if (value == 0 || value == 1) {
      return renderBoolean(value);
    }
    return value;
  };

  const handleDelete = async (tableName, rowData) => {
    try {
      const response = await axios.post("https://fast-api.ilore.ai/delete/", {
        tablename: tableName,
        data: rowData,
      });
      console.log(response.data);
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const renderDeleteButton = (tableName, rowData) => (
    <button onClick={() => handleDelete(tableName, rowData)}>Delete</button>
  );

  const renderTable = (data, columns, tableName) => (
    <table className="data-table">
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>{column}</th>
          ))}
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.length === 0 ? (
          <tr>
            <td colSpan={columns.length + 1}> No data available</td>
          </tr>
        ) : (
          data.map((row, index) => (
            <tr key={index}>
              {columns.map((column, colIndex) => (
                <td key={colIndex}>{renderTableCell(row[column])}</td>
              ))}
              <td>{renderDeleteButton(tableName, row)}</td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );

  return (
    <div class="logs">
      <div class="space"></div>
      <Chip color={connectionStatus ? "success": "danger"} size="md">
        WebSocket Connection:{" "}
        <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
          {connectionStatus ? "Connected" : "Not Connected"}
        </span>
      </Chip>
      <div class="space"></div>
      {error && <div>Error: {error}</div>}
<Divider class="space" orientation="horizontal" />
      <h2>Users</h2>
      {renderTable(users, ["username", "dataset_id"], "Users")}
<Divider class="space" orientation="horizontal" />
      <h2>Dataset Images</h2>
      {renderTable(
        datasets,
        ["username", "dataset_id", "image_name", "is_uploaded"],
        "DatasetImages"
      )}
<Divider class="space" orientation="horizontal" />
      <h2>Models</h2>
      {renderTable(
        models,
        ["username", "dataset_id", "model_id", "is_trained"],
        "Models"
      )}
<Divider class="space" orientation="horizontal" />
      <h2>Image Generations</h2>
      {renderTable(
        generations,
        [
          "username",
          "prompt",
          "request_submitted",
          "generation_id",
          "image_link",
        ],
        "ImageGeneration"
      )}
    </div>
  );
};

export default DataStreamComponent;
