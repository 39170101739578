import React, { useState } from "react";
import "./App.css";
import UserInitialization from "./components/UserInitialization";
import ImageUpload from "./components/ImageUpload";
import Questions from "./components/Questions";
import DisplayAnswers from "./components/DisplayAnswers";
import { Divider } from "@nextui-org/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DataStreamComponent from "./components/DataStreamTables";

function App() {
  const [username, setUsername] = useState("");
  const [currentStep, setCurrentStep] = useState(0); // Use currentStep to manage the flow
  const [userImages, setUserImages] = useState([]);
  const [answers, setAnswers] = useState([]);

  return (
    <Router>
      <Routes>
        <Route path="/logs" element={<DataStreamComponent />} />
        <Route
          path="/"
          element={
            <div className="app">
              <div className="head">
                {/* <div className="heading">Ilore</div> */}
                <img src="/ilore-logo.png" alt="Ilore-Logo" className="logo" />
              </div>
              <Divider class="space" orientation="horizontal" />
              {currentStep === 0 && (
                <UserInitialization
                  setUsername={setUsername}
                  setUserImages={setUserImages}
                  setCurrentStep={setCurrentStep}
                />
              )}
              {currentStep === 1 && (
                <ImageUpload
                  username={username}
                  setCurrentStep={setCurrentStep}
                  setUserImages={setUserImages}
                />
              )}
              {currentStep === 2 && (
                <Questions
                  userImages={userImages}
                  answers={answers}
                  setAnswers={setAnswers}
                  setCurrentStep={setCurrentStep}
                />
              )}
              {currentStep === 3 && (
                <DisplayAnswers
                  answers={answers}
                  username={username}
                  userImages={userImages}
                />
              )}
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
