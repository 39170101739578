import React, { useState } from "react";
import { uploadImages } from "../api";
import { Button } from "@nextui-org/react";

function ImageUpload({ username, setCurrentStep, setUserImages }) {
    const [uploadedImages, setUploadedImages] = useState([]);
    const [error, setError] = useState("");

    const handleUpload = async () => {
        const result = await uploadImages(username, uploadedImages);
        if (result.status) {
            setUserImages(result.images);
            setCurrentStep(2);
        } else {
            setError(result.message);
        }
    };

    return (
        <div>
            <h3>Upload Your Images:</h3>
            <input
                type="file"
                accept="image/*"
                multiple
                onChange={(e) => {
                    const files = Array.from(e.target.files);
                    setUploadedImages(files);
                }}
            />
            <Button size="sm" color="primary" onClick={handleUpload}>Next</Button>
            {error && <div className="error">{error}</div>}
        </div>
    );
}

export default ImageUpload;
