// const BACKEND_URL = "http://localhost:8000";
const BACKEND_URL = "https://fast-api.ilore.ai";


export const initializeUser = async (username) => {
    try {
        const response = await fetch(`${BACKEND_URL}/user/images`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ username }),
        });
        return await response.json();
    } catch (err) {
        console.error(err);
        return { status: false, message: 'Failed to initialize user.' };
    }
};

export const uploadImages = async (username, images) => {
    const formData = new FormData();
    images.forEach((image) => formData.append("images", image));
    formData.append("username", username);
    try {
        const response = await fetch(`${BACKEND_URL}/user/upload`, {
            method: "POST",
            body: formData,
        });
        return await response.json();
    } catch (err) {
        console.error(err);
        return { status: false, message: 'Failed to upload images.' };
    }
};

export const sendPrompt = async (username, prompt) => {
    try {
        const response = await fetch(`${BACKEND_URL}/user/prompt`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ username, prompt }),
        });
        return await response.json();
    } catch (err) {
        console.error(err);
        return { status: false, message: 'Failed to send prompt.' };
    }
};
