import React, { useState } from "react";
import { questions } from "./constants";
import { Button, Chip, Input, Image, Divider } from "@nextui-org/react";

function Questions({ userImages, answers, setAnswers, setCurrentStep }) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [customAnswer, setCustomAnswer] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [error, setError] = useState("");

  const handleNext = async () => {
    const answer = customAnswer || selectedOption;
    let newAnswers = [...answers];
    if (answer !== "") {
      newAnswers[currentQuestion] = answer;
    }
    if (currentQuestion < questions.length - 1) {
      setAnswers(newAnswers);
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption("");
      setCustomAnswer("");
    } else {
      setAnswers(newAnswers);
      setCurrentStep(3);
    }
  };

  return (
    <div className="question-container">
      <Chip color="primary" size="md">
        Your Images
      </Chip>
      <div class="space"></div>
      <div class="image-container">
      {userImages.map((image, idx) => (
        <Image key = {idx} width={150} alt="user upload" src={image}/>
      ))}
</div>
      <div class="space"></div>
      <Divider orientation="horizontal" />
      <div class="space"></div>
      <Chip color="primary" size="md">
        {questions[currentQuestion].question}
      </Chip>
      <div class="space"></div>
      <div className="options">
        {questions[currentQuestion].options.map((option, idx) => (
          <div
            key={idx}
            className={`option ${selectedOption === option ? "selected" : ""}`}
            onClick={() => {
              setSelectedOption(option);
              setCustomAnswer("");
            }}
          >
            {option}
          </div>
        ))}
      </div>
      {/* <input
        type="text"
        placeholder={answers[currentQuestion] || "Or type your answer..."}
        value={customAnswer}
        onChange={(e) => {
          setCustomAnswer(e.target.value);
          setSelectedOption("");
        }}
      /> */}
      <div class="answer">
        <Input
          type="email"
          label="Or type your answer..."
          value={customAnswer}
          labelPlacement="outside"
          onChange={(e) => {
            setCustomAnswer(e.target.value);
            setSelectedOption("");
          }}
        />
      <div class="space"></div>
        <Button size="md" color="primary" onClick={handleNext}>
          Next
        </Button>
</div>
      {error && <p className="error">{error}</p>}
    </div>
  );
}

export default Questions;
