
export const questions = [
  {
    question: "How are you feeling today?",
    options: ["happy", "excited", "sad", "angry", "adventurous"],
  },
  {
    question: "Pick a color for your magical shirt:",
    options: [
      "sparkling red",
      "glowing green",
      "bright blue",
      "shiny black",
      "sparkling pink",
    ],
  },
  {
    question: "Choose a magical place for today's adventure:",
    options: [
      "a sunlit forest",
      "a sparkling river",
      "a mysterious cave",
      "a castle in the clouds",
    ],
  },
  {
    question: "Select your best friend for the adventure:",
    options: ["a dog", "a horse", "a owl", "a dolphin"],
  },
];
