import React, { useState } from "react";
import { initializeUser } from "../api";
import { Input, Button, Divider } from "@nextui-org/react";

function UserInitialization({ setUsername, setUserImages, setCurrentStep }) {
    const [inputUsername, setInputUsername] = useState("");
    const [error, setError] = useState("");

    const handleInitialization = async () => {
        const result = await initializeUser(inputUsername);
        if (result.status) {
            if (result.images && result.images.length > 0) {
                setUsername(inputUsername);
                setUserImages(result.images);
                setCurrentStep(2);
            } else {
                setUsername(inputUsername);
                setCurrentStep(1);
            }
        } else {
            setError(result.message);
        }
    };

    return (
        <div className="initialize-container">
            <Input
                type="email"
        label="Email"
                value={inputUsername}
labelPlacement="outside"
                onChange={(e) => setInputUsername(e.target.value)}
            />
            <Divider class="space" orientation="horizontal" />
      <Button size="md" color="primary" onClick={handleInitialization}>
        Next
      </Button>
            {error && <div className="error">{error}</div>}
        </div>
    );
}

export default UserInitialization;
